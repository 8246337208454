import axios from 'axios'
import _ from 'lodash'

export default {
  data: () => ({
    meters: [],
    isMetersLoading: true,
  }),
  methods: {
    getMeters: _.debounce(function (value) {
      this.isMetersLoading = true
      axios
        .get(`${this.meters_url}&isAvailable=true&search=${value}`)
        .then(response => {
          this.meters = response.data.data
          this.isMetersLoading = false
        })
        .catch(error => {
          this.isMetersLoading = false
          this.$notification.error(error.response.data.message)
        })
    }, 500),
  },
}
